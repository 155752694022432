import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M1390 3903 c0 -6 24 -13 54 -16 68 -7 111 -29 131 -67 22 -43 23
-1326 1 -1369 -22 -41 -61 -62 -127 -68 -33 -3 -59 -10 -59 -15 0 -6 159 -8
413 -5 334 3 425 7 480 20 212 50 323 170 334 361 12 185 -78 329 -241 386
-51 18 -147 37 -216 43 -28 2 -21 5 35 15 196 35 303 108 345 235 6 21 12 74
12 118 -1 141 -64 240 -191 298 -124 57 -169 62 -588 68 -245 3 -383 2 -383
-4z m727 -36 c64 -24 101 -56 128 -112 51 -106 58 -303 15 -419 -24 -63 -77
-115 -137 -135 -21 -7 -93 -15 -160 -18 l-123 -5 0 357 0 358 118 -6 c67 -3
135 -12 159 -20z m88 -738 c111 -57 151 -164 143 -392 -6 -155 -26 -221 -85
-279 -62 -61 -98 -71 -270 -76 l-153 -4 0 392 0 392 158 -4 c144 -3 161 -6
207 -29z"/>
<path d="M3165 3078 c-2 -7 -63 -179 -135 -382 -72 -203 -128 -371 -125 -374
12 -12 28 26 124 302 56 160 103 291 105 293 2 2 7 -12 10 -32 9 -44 115 -362
159 -477 l33 -83 138 -3 c109 -2 137 0 134 10 -3 7 -68 181 -144 385 l-139
373 -78 0 c-53 0 -79 -4 -82 -12z"/>
<path d="M2241 2103 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M2765 2020 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2358 1983 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3358 1983 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2665 1663 c-84 -235 -115 -270 -242 -281 -35 -2 -63 -9 -63 -13 0
-5 131 -9 290 -9 287 0 382 11 191 23 -212 13 -230 54 -142 305 34 95 46 142
38 142 -8 0 -38 -71 -72 -167z"/>
<path d="M3520 1821 c0 -6 21 -65 46 -133 54 -146 66 -231 38 -266 -22 -27
-76 -41 -156 -42 -32 0 -58 -4 -58 -10 0 -6 123 -10 350 -10 324 0 410 7 313
25 -84 15 -106 48 -193 278 l-63 167 -138 0 c-80 0 -139 -4 -139 -9z"/>
<path d="M1488 1753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1768 1750 c12 -5 22 -17 23 -27 0 -11 3 -13 6 -5 7 17 -15 42 -36
41 -11 0 -9 -3 7 -9z"/>
<path d="M2170 1756 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M2072 1730 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1490 1723 c8 -3 19 -11 23 -17 6 -7 7 -6 4 4 -2 8 -12 16 -23 17
-13 2 -15 1 -4 -4z"/>
<path d="M2170 1726 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M1758 1693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2048 1693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2178 1693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
